export default [
  { name: '年年。好區', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '', offset: -50, mobileOffset: 0  },
  { name: '年年。交通', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '', offset: -50, mobileOffset: -100 },
  { name: '年年。好學', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '', offset:  720, mobileOffset: 300 },
  { name: '年年。地標', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '', offset: -50, mobileOffset: -50 },
  { name: '年年。團隊', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '', offset: 0, mobileOffset: -50 },
  //{ name: '年年。公設', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '', offset: -50, mobileOffset: -50 },
  //{ name: '年年。空間', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '', offset: -50, mobileOffset: -50 },
  { name: '年年。經典', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '', offset: -50, mobileOffset: -50 },
]
